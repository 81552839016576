/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react'

import { Flexbox } from '@stage-ui/core'
import { Close } from '@stage-ui/icons'

const Video = (props: { play: boolean; onClose: () => void }) => {
  const { play, onClose } = props

  return (
    <Flexbox
      justifyContent="center"
      onClick={() => {
        onClose()
      }}
      style={{
        position: 'fixed',
        pointerEvents: play ? 'all' : 'none',
        opacity: play ? 1 : 0,
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        zIndex: 1000000,
        background: 'rgba(0,0,0,0.8)',
        backdropFilter: 'blur(10px)',
        transition: 'all 1s',
        padding: '4rem',
      }}
    >
      {play && (
        <video
          onClick={(e) => {
            e.stopPropagation()
          }}
          autoPlay
          controls
          src="https://redsk.ai/bucket/assets/promo.mp4"
          style={{
            width: '80vw',
            maxWidth: '100wh',
          }}
        />
      )}
      {play && <Close color="white" size="2rem" mr="-3rem" ml="1rem" />}
    </Flexbox>
  )
}

export default Video
